import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "card mb-5 mb-xxl-8" }
const _hoisted_4 = { class: "card-body pb-0" }
const _hoisted_5 = { class: "row mb-6" }
const _hoisted_6 = { class: "col-lg-12 col-form-label required fw-bold fs-6" }
const _hoisted_7 = { class: "col-lg-12" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-lg-12 fv-row" }
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = { class: "form-check form-check-inline form-check-solid mt-5" }
const _hoisted_12 = { class: "fw-bold ps-2 fs-6" }
const _hoisted_13 = { class: "card-footer d-flex justify-content-end py-6 px-9" }
const _hoisted_14 = {
  type: "reset",
  class: "btn btn-white btn-active-light-primary me-2"
}
const _hoisted_15 = { class: "indicator-label" }
const _hoisted_16 = { class: "indicator-progress" }
const _hoisted_17 = { class: "card bg-success bg-opacity-25 h-100" }
const _hoisted_18 = { class: "card-header flex-nowrap border-0 pt-9" }
const _hoisted_19 = { class: "card-title w-100 d-block" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "col-10" }
const _hoisted_22 = {
  key: 0,
  href: "#",
  class: "fs-4 fw-bold text-hover-primary text-gray-900 m-0"
}
const _hoisted_23 = {
  key: 1,
  href: "#",
  class: "fs-4 fw-bold text-hover-primary text-gray-900 m-0"
}
const _hoisted_24 = { class: "col-2" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { class: "card-body d-flex flex-column pb-8" }
const _hoisted_27 = { class: "d-flex align-items-center flex-wrap mb-5 fs-6" }
const _hoisted_28 = { class: "text-success fw-bolder me-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Form, {
            id: "kt_add_action_form",
            class: "form",
            novalidate: "novalidate"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("pages.ideas.ideaDesc")), 1),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _withDirectives(_createElementVNode("textarea", {
                        class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                        placeholder: _ctx.$t('pages.ideas.ideaDesc'),
                        name: "idea",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.idea.idea) = $event))
                      }, null, 8, _hoisted_10), [
                        [_vModelText, this.idea.idea]
                      ]),
                      _createElementVNode("label", _hoisted_11, [
                        _withDirectives(_createElementVNode("input", {
                          class: "form-check-input",
                          name: "anonymous",
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.idea.anonymous) = $event)),
                          type: "checkbox"
                        }, null, 512), [
                          [_vModelCheckbox, this.idea.anonymous]
                        ]),
                        _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("pages.ideas.anonymous")), 1)
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("button", _hoisted_14, _toDisplayString(_ctx.$t("general.cancel")), 1),
          _createElementVNode("button", {
            type: "submit",
            id: "kt_account_profile_details_submit",
            ref: "submitButton1",
            class: "btn btn-primary",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.addIdea && _ctx.addIdea(...args)))
          }, [
            _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("general.save")), 1),
            _createElementVNode("span", _hoisted_16, [
              _createTextVNode(_toDisplayString(_ctx.$t("general.pleaseWait")) + " ", 1),
              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1))
            ])
          ], 512)
        ])
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.category.ideas, (idea) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-4 mb-4",
        key: idea.pk
      }, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fas fa-lightbulb fs-3 me-3 text-gray-900" }, null, -1)),
                  (idea.anonymous)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_22, "Anonyme"))
                    : (_openBlock(), _createElementBlock("a", _hoisted_23, _toDisplayString(idea.userprofile.lastname) + " " + _toDisplayString(idea.userprofile.firstname), 1)),
                  _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                  _createElementVNode("i", null, _toDisplayString(_ctx.$moment(idea.date_created).format("DD/MM/YYYY")), 1)
                ]),
                _createElementVNode("div", _hoisted_24, [
                  (this.$store.getters.currentUser.user.userprofile.id === idea.userprofile.id)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: "#",
                        onClick: ($event: any) => (_ctx.deleteIdea(idea)),
                        class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm d-flex align-item-end"
                      }, _cache[6] || (_cache[6] = [
                        _createStaticVNode("<span class=\"svg-icon svg-icon-3\"><svg fill=\"none\" viewBox=\"0 0 24 24\" height=\"24\" width=\"24\" xmlns=\"http://www.w3.org/2000/svg\"><path xmlns=\"http://www.w3.org/2000/svg\" d=\"M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z\" fill=\"black\"></path><path xmlns=\"http://www.w3.org/2000/svg\" opacity=\"0.5\" d=\"M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z\" fill=\"black\"></path><path xmlns=\"http://www.w3.org/2000/svg\" opacity=\"0.5\" d=\"M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z\" fill=\"black\"></path></svg></span>", 1)
                      ]), 8, _hoisted_25))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, _toDisplayString(idea.idea), 1)
            ])
          ])
        ])
      ]))
    }), 128))
  ]))
}